
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "dropdown" })
export default class Dropdown extends Vue {
  private active = false;

  private toggle() {
    this.active = !this.active;
  }
}
